import Axios from 'axios';
import URL from '../config/url';
import { Message } from 'element-ui'
const baseURL = URL.yingjiURL;

const service = Axios.create({ withCredentials: false, baseURL })

// 请求拦截器
service.interceptors.request.use(config => {
  localStorage.getItem('token') && (config.headers['token'] = localStorage.getItem('token'))
  localStorage.getItem("organ") && (config.headers['organ'] = localStorage.getItem("organ"))
  localStorage.getItem("actor") && (config.headers['actor'] = localStorage.getItem("actor"))
  localStorage.getItem('branch') && (config.headers['branch'] = localStorage.getItem('branch'))
  return config
}, err => {
  return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})


function API2 (config) {
  const {
    url = '',
    data = null,
    params = null,
    headers = { "content-type": "application/json" },
    method = 'get',
    timeout = 10000
  } = config || {}
  return new Promise((resolve, reject) => {
    service({
      method, // 请求方式
      url, // 请求路径
      timeout, // 请求超时
      headers, // header体
      data, // body参数
      params //  URL参数
    }).then(res => {
      if (res.data.code) {
        Message({
          showClose: true,
          message: res.data.message,
          type: 'error'
        })
        if (+res.data.code === 303) {
          localStorage.clear()
          sessionStorage.clear()
          window.location = `https://${window.location.host}/login`
          resolve('跳转登录页')
        }
      }
      resolve({
        ...res.data,
        headers: res.headers
      })
    }).catch(err => {
      reject(err)
    })
  })
}

export default API2
